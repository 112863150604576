// Color names generated with HexColorPedia
// https://hexcolorpedia.com/

const INCUBI_DARKNESS = 'incubi-darkness';
const POP_THAT_GUM = 'pop-that-gum';
const BLUEALICIOUS = 'bluealicious';
const ONCE_BITTEN = 'once-bitten';

type ColorMappingType = {
    [key: string]: string;
};

export const categoryColorMapping: ColorMappingType = {
    // ONCE BITTEN COLOR
    'QUICK TIPS': ONCE_BITTEN,
    'CASE STUDIES': ONCE_BITTEN,
    'EVENTBRITE BOOST': ONCE_BITTEN,
    'UPDATES AND ANOUNCEMENTS': ONCE_BITTEN,
    TOOLS: ONCE_BITTEN,
    'TOOLS & FEATURES': ONCE_BITTEN,

    // BLUEALICIOUS
    'NEWS & TRENDS': BLUEALICIOUS,
    NEWS: BLUEALICIOUS,
    TRENDS: BLUEALICIOUS,
    REPORTS: BLUEALICIOUS,

    // POP_THAT_GUM
    COMMUNITY: POP_THAT_GUM,
    'CREATOR SPOTLIGHTS': POP_THAT_GUM,

    // INCUBI_DARKNESS
    'EVENT PLANNING': INCUBI_DARKNESS,
    'TIPS & GUIDES': INCUBI_DARKNESS,
    'EVENT MANAGEMENT': INCUBI_DARKNESS,
    'EVENT MARKETING': INCUBI_DARKNESS,
};
