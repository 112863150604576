import { Grid } from '@eventbrite/wagtail-components';
import React from 'react';
import { useTheme } from '../../../utils/context/theme-context';
import { BaseModuleProps } from '../types';
import { convertContentfulVariantToEnum } from '../utils';
import ResourceHubCard from './ResourceHubCard';
import './ResourceHubModule.scss';
export interface ResourceInfoProps {
    id: string;
    description: string;
    link: string;
    title: string;
    image: {
        sourceUrl: string;
        altText: string;
    };
    category: {
        name: string;
        link: string;
    };
}
export interface ResourceHubModuleProps {
    header: string;
    eyebrow: string;
    useH1?: boolean;
    isContentFromContentful?: boolean;
    articlesInfo: ResourceInfoProps[];
}

const ResourceHubModule = ({
    articlesInfo,
    header,
    eyebrow,
    variant,
    isContentFromContentful = false,
    useH1 = false,
}: ResourceHubModuleProps & BaseModuleProps) => {
    if (isContentFromContentful) {
        variant = convertContentfulVariantToEnum(variant);
    }
    const baseOptions = { variant };
    const theme = useTheme();
    const [backgroundClass] = theme.generateStyles('background', baseOptions);

    const [textClass] = theme.generateStyles('text', baseOptions);

    return (
        <section className={`resource-hub__container ${backgroundClass}`}>
            <div className="resource-hub__headline">
                <p className={`resource-hub__eyebrow`}>{eyebrow}</p>
                {useH1 ? (
                    <h1 className={`resource-hub__header ${textClass}`}>
                        {header}
                    </h1>
                ) : (
                    <h2 className={`resource-hub__header ${textClass}`}>
                        {header}
                    </h2>
                )}
            </div>

            <Grid
                base={{
                    gap: 0,
                    rowGap: 32,
                    columnGap: 50,
                    columnCount: 1,
                }}
                small={{
                    rowGap: 12,
                    columnGap: 12,
                }}
                medium={{
                    rowGap: 32,
                    columnGap: 32,
                }}
                xlarge={{
                    rowGap: 32,
                    columnGap: 50,
                    columnCount: 2,
                }}
                className="resource-hub__grid"
            >
                {articlesInfo?.map((info) => (
                    <ResourceHubCard
                        key={info.id}
                        variant={variant}
                        {...info}
                    />
                ))}
            </Grid>
        </section>
    );
};

export default ResourceHubModule;
