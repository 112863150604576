import classNames from 'classnames';
import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { useTheme } from '../../../utils/context/theme-context';
import { BaseModuleProps } from '../types';
import './ResourceHubCard.scss';
import { categoryColorMapping } from './utils/categoryToColorMappings';

export interface ResourceCardProps {
    description: string;
    link: string;
    title: string;
    image: {
        sourceUrl: string;
        altText: string;
    };
    category?: {
        name: string;
        link: string;
    };
}

const ResourceHubCard = ({
    description,
    link,
    title,
    image,
    category,
    variant,
}: ResourceCardProps & BaseModuleProps) => {
    const baseOptions = { variant };
    const theme = useTheme();
    const [textClass] = theme.generateStyles('text', baseOptions);
    const categoryName = category?.name?.toUpperCase();
    const colorMapping = categoryName
        ? categoryColorMapping[categoryName]
        : undefined;

    const categoryTagClasses = classNames(
        {
            [`resource-hub__card__category-tag__${colorMapping}`]: colorMapping,
        },
        'resource-hub__card__category-tag',
    );

    return (
        <div className="resource-hub__card">
            <div className="resource-hub__card__image-container">
                <a
                    className="resource-hub__card__image-link"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    aria-label={sanitizeHtml(title)}
                >
                    <img
                        className="resource-hub__card__image"
                        src={image.sourceUrl}
                        alt={image.altText}
                        role="button"
                    />
                </a>
            </div>
            <a
                className={categoryTagClasses}
                href={category?.link}
                target="_blank"
                rel="noreferrer"
            >
                {category?.name}
            </a>
            <br />

            <a
                className="resource-hub__card__category-title"
                href={link}
                target="_blank"
                rel="noreferrer"
            >
                <h2
                    className={`resource-hub__card__category-title__header ${textClass}`}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: title,
                    }}
                ></h2>
            </a>
            <span
                className={`resource-hub__card__description ${textClass}`}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: description,
                }}
            ></span>
        </div>
    );
};

export default ResourceHubCard;
